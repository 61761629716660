const PageReset = (props) => (
  <div className="zoom-button" title="Reset">
    <svg viewBox="0 0 46 46" xmlns="http://www.w3.org/2000/svg" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)" onClick={props.clickAction}>
      <rect className="cls-2" width="46" height="46" rx="5" ry="5" />
      <path
        className="cls-1"
        d="m23,36.71c-7.56,0-13.72-6.15-13.72-13.71,0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5c0,5.91,4.81,10.71,10.72,10.71s10.71-4.81,10.71-10.71-4.81-10.72-10.71-10.72c-3.81,0-7.36,2.05-9.27,5.34-.42.72-1.33.96-2.05.54-.72-.42-.96-1.33-.54-2.05,2.45-4.21,7-6.83,11.87-6.83,7.56,0,13.71,6.15,13.71,13.72s-6.15,13.71-13.71,13.71Z"
      />
      <path
        className="cls-1"
        d="m18.42,18.39h-6.11c-.83,0-1.5-.67-1.5-1.5v-6.11c0-.83.67-1.5,1.5-1.5s1.5.67,1.5,1.5v4.61h4.61c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
      />
    </svg>
  </div>
);

export default PageReset;
