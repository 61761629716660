import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routing/Routes";

const App = () => (
  <Fragment>
    <Router basename="/">
      <Switch>
        <Route component={Routes} />
      </Switch>
    </Router>
  </Fragment>
);

export default App;
