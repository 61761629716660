const ZoomIn = (props) => (
  <div className="zoom-button"  title="Zoom in">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 46" onClick={props.clickAction}>
      <rect className="cls-2" width="46" height="46" rx="4" ry="4" />
      <path
        className="cls-1"
        d="m34.5,24.91h-9.58v9.58c0,.51-.2,1-.56,1.36-.36.36-.85.56-1.36.56s-1-.2-1.36-.56c-.36-.36-.56-.85-.56-1.36v-9.58h-9.58c-.51,0-1-.2-1.36-.56-.36-.36-.56-.85-.56-1.36s.2-1,.56-1.36c.36-.36.85-.56,1.36-.56h9.58v-9.58c0-.51.2-1,.56-1.36.36-.36.85-.56,1.36-.56s1,.2,1.36.56c.36.36.56.85.56,1.36v9.58h9.58c.51,0,1,.2,1.36.56.36.36.56.85.56,1.36s-.2,1-.56,1.36c-.36.36-.85.56-1.36.56Z"
      />
    </svg>
  </div>
);

export default ZoomIn;
