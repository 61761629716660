import React from "react";
import { Route, Switch } from "react-router-dom";
import MapUK from "../MapUK";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={MapUK} />
        {/* <Route exact path="/nologo" component={MapUK} /> */}
        <Route exact path="/:id" component={MapUK} />
      </Switch>
    </>
  );
};

export default Routes;
